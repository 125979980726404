import React, { Suspense } from "react";

import {
  Box,
  Heading,
  Container,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
  Flex,
  Stack,
} from "@chakra-ui/react";

import { Canvas, useThree } from "react-three-fiber";
import {
  Loader,
  OrbitControls,
  softShadows,
  Html,
  Plane,
  Sphere,
} from "@react-three/drei";

import PictureBearbat from "../components/pictureBearbat";

import BearbatModel from "../components/3D/Bearbat.js";

import Lights from "../components/3D/lights";
import Floor from "../components/3D/floor";
// import GLTF from "../components/3D/bear.glb";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Map from "../components/mapComponent";

const ZoomWithOrbital = () => {
  const { gl, camera } = useThree();
  // useSpring({
  //   from: {
  //     z: 30,
  //   },
  //   x: -5,
  //   y: 4,
  //   z: 4,
  //   // React Springs onFrame
  //   onFrame: ({ x, y, z }) => {
  //     camera.position.x = x;
  //     camera.position.y = y;
  //     camera.position.z = z;
  //   },
  // });
  return (
    // Oribital controls via drei
    <OrbitControls
      enableZoom={true}
      enablePan={false}
      target={[0, 0, 0]}
      args={[camera, gl.domElement]}
    />
  );
};

const PicaturesPage = () => (
  <Layout>
    <SEO title="3d models and pictures of the bearbat" />

    {/* <Container
      p="2rem"
      // h="400px"
      bg="gray.100"
      borderRadius="xl"
      centerContent
      >
    <Heading>The FAQ's!</Heading> */}
    <Box borderWidth="1px" m="0 auto" w="80%" minH="80vh">
      <Canvas
        colorManagement
        shadowMap
        camera={{ position: [3, 7, 12], fov: 40 }}
      >
        <Lights />
        <Suspense fallback={<Html>loading...X</Html>}>
          {/* <Plane args={[4, 2]} /> */}

          <BearbatModel />

          <Floor />
          <ZoomWithOrbital />
        </Suspense>
      </Canvas>
      <Loader />

      {/* </Container> */}
    </Box>
  </Layout>
);

export default PicaturesPage;
