import React from "react";

const Lights = () => {
  return (
    <>
      <fog attach="fog" args={["#fff", 0, 22]} />
      <ambientLight intensity={0.5} />
      <directionalLight
        castShadow
        position={[6, 14, 25]}
        intensity={0.15}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={50}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
      <pointLight position={[0, 27, 0]} intensity={4.1} />
    </>
  );
};

export default Lights;
