/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei/useGLTF";

export default function Model(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/Bearbat.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        material={nodes.Sphere.material}
        geometry={nodes.Sphere.geometry}
        position={[0.16, 3.13, 1.27]}
        scale={[0.03, 0.03, 0.03]}
      />
      <mesh
        material={nodes.body_backup.material}
        geometry={nodes.body_backup.geometry}
        position={[7.16, 0, 0]}
      />
      <mesh material={nodes.Body.material} geometry={nodes.Body.geometry} />
      <mesh
        material={nodes.wings.material}
        geometry={nodes.wings.geometry}
        position={[2.27, 3, 0.73]}
        rotation={[1.49, 0.38, 0.23]}
        scale={[2.23, 1.2, 1.12]}
      />
      <mesh
        material={nodes.pawClaw.material}
        geometry={nodes.pawClaw.geometry}
        position={[0.57, 0.27, 0.24]}
        rotation={[2.16, 1.04, -1.13]}
        scale={[0.06, 0.06, 0.06]}
      />
      <mesh
        material={nodes.pawClaw001.material}
        geometry={nodes.pawClaw001.geometry}
        position={[0.51, 0.27, 0.29]}
        rotation={[2.16, 1.04, -1.13]}
        scale={[0.06, 0.06, 0.06]}
      />
      <mesh
        material={nodes.pawClaw002.material}
        geometry={nodes.pawClaw002.geometry}
        position={[0.43, 0.27, 0.31]}
        rotation={[1.7, 1.13, -0.62]}
        scale={[0.06, 0.06, 0.06]}
      />
      <mesh
        material={nodes.pawClaw003.material}
        geometry={nodes.pawClaw003.geometry}
        position={[0.35, 0.27, 0.31]}
        rotation={[0.02, 1.42, 0.97]}
        scale={[0.06, 0.06, 0.06]}
      />
      <mesh
        material={nodes.Top_Claw.material}
        geometry={nodes.Top_Claw.geometry}
        position={[1.88, 3.41, 1.3]}
        rotation={[2.16, 1.04, -1.13]}
        scale={[0.13, 0.13, 0.13]}
      />
      <mesh
        material={nodes.Top_Claw001.material}
        geometry={nodes.Top_Claw001.geometry}
        position={[0.05, 2.78, 1.37]}
        rotation={[0.15, -1.09, -0.77]}
        scale={[0.02, 0.03, 0.04]}
      />
      <mesh
        material={nodes.Top_Claw002.material}
        geometry={nodes.Top_Claw002.geometry}
        position={[0.02, 2.88, 1.42]}
        rotation={[-3.01, -1.09, -0.77]}
        scale={[0.01, 0.02, 0.03]}
      />
      <mesh
        material={nodes.Top_Claw003.material}
        geometry={nodes.Top_Claw003.geometry}
        position={[0.04, 2.88, 1.39]}
        rotation={[-1.21, -1.51, 1.05]}
        scale={[0.01, 0.02, 0.02]}
      />
      <mesh
        material={nodes.Top_Claw004.material}
        geometry={nodes.Top_Claw004.geometry}
        position={[0.05, 2.88, 1.36]}
        rotation={[-3.01, -1.09, -0.77]}
        scale={[0.01, 0.01, 0.02]}
      />
      <mesh
        material={nodes.Top_Claw005.material}
        geometry={nodes.Top_Claw005.geometry}
        position={[0.06, 2.88, 1.38]}
        rotation={[-3.01, -1.09, -0.77]}
        scale={[0.01, 0.02, 0.03]}
      />
      <mesh
        material={nodes.wingClaw.material}
        geometry={nodes.wingClaw.geometry}
        position={[3.23, 2.18, 0.98]}
        rotation={[3.03, -0.69, -0.05]}
        scale={[0.08, 0.08, 0.08]}
      />
      <mesh
        material={nodes.wingClaw001.material}
        geometry={nodes.wingClaw001.geometry}
        position={[2.39, 1.97, 0.94]}
        rotation={[3.03, -0.69, -0.05]}
        scale={[0.06, 0.06, 0.06]}
      />
      <mesh
        material={nodes.wingClaw002.material}
        geometry={nodes.wingClaw002.geometry}
        position={[1.79, 1.8, 0.94]}
        rotation={[3.03, -0.69, -0.05]}
        scale={[0.06, 0.06, 0.06]}
      />
    </group>
  );
}

useGLTF.preload("/Bearbat.glb");
